import { Button, Flex, Spacer, Title } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import { SmallFeatureCardsBlockRecord } from '~/config/generated/graphql';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import ArticleList from '../../Blog/components/ArticleList';
import { Color } from '@kvdbil/components/types/Types';
import Link from '~/App/shared/components/Link';

const ContentContainer = styled.div<{ centerText: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ centerText }) => (centerText ? 'center' : 'start')};

  p {
    margin: 0.5rem 0 2rem;
  }
`;

const StyledFlex = styled(Flex)`
  max-width: 24.125rem;
  background-color: red;
  margin: 2rem auto auto;

  a {
    width: 100%;
  }
`;

interface Props extends SmallFeatureCardsBlockRecord {
  isLoading: boolean;
  centerText?: boolean;
}

const SmallFeatureCards = ({
  title,
  text,
  articles,
  isLoading,
  button,
  centerText = false
}: Props) => {
  return (
    <>
      <ContentContainer centerText={centerText}>
        {title && <Title as={'h3'}>{title}</Title>}
        {!isEmptyDocument(text) && <StructuredText data={text} />}
      </ContentContainer>
      {isEmptyDocument(text) && <Spacer />}
      <ArticleList isLoading={isLoading} articles={articles} />
      {button && (
        <StyledFlex>
          <Link to={button[0].url}>
            <Button size="large" color={button[0].color as Color} fullWidth>
              {button[0].text}
            </Button>
          </Link>
        </StyledFlex>
      )}
    </>
  );
};

export default SmallFeatureCards;
